<template>
    <b-modal id="modal-join"  ref="modal"   :title="$t('profil_team.join.text')" title-class="font-18" hide-footer @shown="create">
                    <div role="tablist">

                        <b-card no-body class="mb-1 shadow-none">
                            <b-card-header header-tag="header" role="tab" v-b-toggle.code>
                                <h6 class="m-0">
                                    <a  class="text-dark" href="#">{{$t('profil_team.join.desc')}}</a>
                                </h6>
                            </b-card-header>
                            <b-collapse id="code" visible accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <form id="form1" method="POST" @submit.prevent="join">
                                    <b-card-text class="row">
                                        <div class="col-10 ">
                                             <input @keyup.enter="join" type="text"
                                             class="form-control chat-input pr-3 mr-2"
                                              style="border-radius: 30px" v-model="code"
                                               :placeholder="$t('profil_team.join.code')"
                                                :class="{ 'is-invalid': codesubmit && $v.code.$error }"/>
                                                 <div v-if="codesubmit && $v.code.$error" class=" invalid-feedback">
                            <span v-if="!$v.code.required"> {{$t('validation.required')}}</span>
                            <span v-if="!$v.code.valid"> xxxx-xxxx-xxxx-xxxx</span>
                            
                        </div>    
                                            </div>
                                           
                                            <input type="submit" style="height:35px" class="btn btn-rounded col-2 btn-secondary" :value="$t('profil_team.save')" />
                                    
                                    </b-card-text>
                                   
                                    </form>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                        <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" role="tab" v-b-toggle.new>
                                <h6 class="m-0">
                                    <a  class="text-dark" href="#">{{$t('profil_team.create.desc')}}</a>
                                </h6>
                            </b-card-header>
                            <b-collapse id="new" accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <b-card-text>
                                        <create-team v-on:refresh="$refs['modal'].hide()"/>
                                    </b-card-text>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                    </div>
    </b-modal>
</template>

<script>
    import helper from "@/assets/helpers/helper";
     import {required} from "vuelidate/lib/validators";
    import CreateTeam from "../createTeam";

    export default {
        name: "join",
        components: {CreateTeam},
        props:['id'],
        data(){
            return{

                codesubmit:false,
                code:'',

            }
        },
        validations: {
            code:{
                required,
                valid: function(value) {
                     return /^[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}$/.test(value)
               }
            }
        },
        methods: {
            create(){

                this.codesubmit =  false
                this.code=''

            },
           async join(){
                this.codesubmit = true;


                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {

                    return;
                }
                let link = (this.id==0)?'teams/join':'teams/' + this.id+'/join'
                let r = await helper.addItem(link,{code  : this.code})
                if(r) {
                    this.$emit('refresh')
                    this.$refs['modal'].hide()
                    this.$router.push({ name: 'ProfilTeam', params: { id: r.id }})
                   
                }
            },
        },
    }
</script>
<style scoped>
</style>
<template>
    <b-modal id="edit-team" ref="modal"  :title="$t('profil_team.create.edit')" title-class="font-18" hide-footer @shown="create">
        <form>
            <div class="row">

                <div class="col-xl-9 m-auto font-size-14">
                    <form id="form"  method="POST" @submit.prevent="handleSubmit">
                    <div class="form-group mt-3 mb-0">
                        <label>{{$t('profil_team.name')}} :</label>
                        <b-form-input type="text" v-model="team.name" :class="{ 'is-invalid': typesubmit && $v.team.name.$error }"></b-form-input>
                        <div v-if="typesubmit && $v.team.name.$error" class="invalid-feedback">
                            <span v-if="!$v.team.name.required"> {{$t('validation.required')}}</span>
                            <span v-if="!$v.team.name.alphaNum"> {{$t('validation.invalid')}}</span>
                            <span v-if="!$v.team.name.minLength || !$v.team.name.maxLength">3-15 caracters</span>
                        </div>
                    </div>
                    <div class="form-group mt-3 mb-0">
                        <label>{{$t('profil_team.country')}} :</label>
                        <country-select id="country" v-model="team.country" :country="team.country"
                                        :blackList="['EH']" class="custom-select" :countryName="true"
                                        :class="{ 'is-invalid': typesubmit && $v.team.country.$error }"/>
                        <div v-if="typesubmit && $v.team.country.$error" class="invalid-feedback">
                            <span v-if="!$v.team.country.required"> {{$t('validation.required')}}</span>
                        </div>
                    </div>
                <input class="btn btn-primary float-right mt-3" :value="$t('profil_team.submit')" type="submit" />
                    </form>
                </div>
            </div>
        </form>
    </b-modal>
</template>

<script>
    import helper from '@/assets/helpers/helper';
    import {alphaNum, maxLength, minLength, required} from "vuelidate/lib/validators";
    export default {
        name: "editTeam",
        props:['name','country'],
        data(){
            return{
                typesubmit:false,
                team: {
                    name: this.name,
                    country: this.country
                }
            }
        },
        validations: {
            team:{
                name: {required,alphaNum,minLength: minLength(3),maxLength: maxLength(15)},
                country: {required},
            },
        },

        methods: {
            create(){
                this.typesubmit = false
                this.team = {
                    name: this.name,
                    country: this.country
                }
            },
            handleSubmit() {
                this.typesubmit = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.editTeam();

            },
            async editTeam() {
               let re =  await helper.postReturnData('teams/'+this.$route.params.id,this.team);
                if(re)   {
                    this.$emit('refresh',this.team)
                    this.$refs['modal'].hide()
                }
            },
        },
    }
</script>

<style scoped>

</style>
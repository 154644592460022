<template>
    <div class="card">
        <div class="card-body">

            <div class="row align-items-center" v-if="info.name">
                <div class="col-lg-3 col-xl-3 col-md-6 col-sm-12 mb-2">
                    <div class="media">
                        <div class="mr-3">
                            <b-avatar class="outer"  :src="$store.state.team.avatar" size="lg"  v-if="info.avatar && info.is_member" ></b-avatar>
                            <b-avatar class="outer"  :src="info.avatar" size="lg"  v-if="info.avatar && !info.is_member" ></b-avatar>
                            <b-avatar v-if="!info.avatar" variant="primary" size="lg" :text="(info.name).substr(0, 1)" class="align-baseline outer"></b-avatar>
                           <i class="bx bx-camera mx-2 inner text-white font-size-16"  v-if="info.is_captain" role="button" @click="show = true"></i>
                          <upload-avatar v-if="info.is_captain && show" type="team"  v-on:close="show=false"  :url="'teams/'+info.id+'/avatar'"/>
                        </div>
                        <div class="media-body align-self-center">
                            <div class="text-muted">
                                <p class="mb-0 ">{{info.country}}</p>

                              <h5 class="mb-1">{{info.name}}</h5>
                              <div v-if="info.is_captain">
                                <button type="button" @click="$bvModal.show('edit-team')" class="btn btn-primary btn-sm"><i class="fa fa-edit mr-1"></i>{{$t('profil_team.edit_team')}}</button>
                              <edit-team v-on:refresh="refresh" :name="info.name" :country="info.country"/>
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-9 col-xl-9 col-md-6 col-sm-12 mb-2 text-lg-center mt-lg-0 align-self-center">

                    <div class="row align-items-center justify-content-center">
                            <div class="col-xl-1 col-lg-1 col-md-4 col-sm-6 mb-2  w-50">
                                    <p class="text-muted mb-2">{{$t('profil_team.score')}}</p>
                                    <h6 class="mb-0">{{info.score?info.score:0}}</h6>
                            </div>

                            <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 mb-2  w-50">
                                    <p class="text-muted mb-2">{{$t('profil_team.solved_chall')}}</p>
                                    <h6 class="mb-0">{{info.challenge_solved_count}}</h6>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 mb-2  w-50" >
                                    <p class="text-muted  mb-2">{{$t('profil_team.competitions')}}</p>
                                    <h6 class="mb-0">{{info.competitions_count}}</h6>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 mb-2  w-50" v-if="info.country">
                                    <p class="text-muted  mb-2">{{$t('profil_team.country_rank')}}</p>
                                    <h6 class="mb-0">{{info.ranks.length?info.ranks[0].rank_country:'-'}}</h6>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 mb-2  w-50">
                                    <p class="text-muted  mb-2">{{$t('profil_team.world_rank')}}</p>
                                    <h6 class="mb-0">{{info.ranks.length?info.ranks[0].rank_world:'-'}}</h6>
                            </div>
                            <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 mb-2  w-50">
                                    <p class="text-muted mb-2">
                                        <i class="bx text-primary mr-2  bx bxs-award"></i>{{$t('profil_team.captain')}}</p>

                                    <router-link class="h6 mb-0 link" :to="{name:'ProfilUser',params:{id:info.captain.user_id}}" v-if="info.captain">{{info.captain.username}}</router-link>
                                    <span v-else>--</span>
                            </div>
                            <div class="col-xl-1 col-lg-1 col-md-4 col-sm-6 mb-2  w-50">
                              <span   @click="unjoin" role="button" class="btn btn-danger font-size-12 m-1 mt-2" v-if="info.is_member"  >{{$t('profil_team.unjoin.title')}}</span>
                              <unjoin-confirmation :id="$route.params.id" type="teams" v-on:refresh="refreshUnjoin"/>
                              <span @click="join" role="button" class="btn btn-success  font-size-12 m-1 mt-2" v-if="!info.is_member">{{$t('profil_team.join.title')}}</span>
                              <join :id="$route.params.id"  v-on:refresh="refreshJoin"/>
                            </div>
                        </div>
                </div>
            </div>
            <div class="row align-items-center justify-content-center" v-else>
                <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 mb-2  w-50">
                    <div class="row">
                        <b-skeleton type="avatar"  class="col-sm-2 mr-2"></b-skeleton>
                        <div class="col-sm-9">
                            <b-skeleton class="mb-2"></b-skeleton>
                            <b-skeleton class="mb-2"></b-skeleton>
                        </div>
                    </div>
                </div>
                <div class="col-xl-1 col-lg-1 col-md-4 col-sm-6 mb-2  w-50">
                    <p class="text-muted  text-truncate mb-2">{{$t('profil_team.score')}}</p>
                    <b-skeleton ></b-skeleton>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 mb-2  w-50">
                    <p class="text-muted  text-truncate mb-2">{{$t('profil_team.solved_chall')}}</p>
                    <b-skeleton ></b-skeleton>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 mb-2  w-50">
                    <p class="text-muted  text-truncate mb-2">{{$t('profil_team.country_rank')}}</p>
                    <b-skeleton></b-skeleton>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 mb-2  w-50">
                    <p class="text-muted  text-truncate mb-2">{{$t('profil_team.world_rank')}}</p>
                    <b-skeleton></b-skeleton>
                </div>
                <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 mb-2  w-50">
                    <p class="text-muted  text-truncate mb-2">{{$t('profil_team.captain')}}</p>
                    <b-skeleton ></b-skeleton>
                </div>
          <div class="col-xl-1 col-lg-1 col-md-4 col-sm-6 mb-2  w-50">
            <b-skeleton type="button"></b-skeleton>

          </div>

            </div>

            <!-- end row -->
        </div>
    </div>
</template>

<script>
    import Join from "@/components/popups/join";
    import UnjoinConfirmation from "@/components/popups/unjoinconfirmation";
    import EditTeam from "./popups/editTeam";
    import UploadAvatar from "./uploadAvatar";
    export default {
        name: "profileTeamHeader",
        components: {UploadAvatar, EditTeam, UnjoinConfirmation, Join},
        props:['info'],
      data(){
          return{
            show:false
          }
      },
        methods: {
            refreshUnjoin(){
                this.info.is_member = false
               if(this.info.is_captain){
                   this.info.is_captain = false
                   this.info.captain = null
               }
                this.$emit('refresh-members')
            },
            refreshJoin(){
                this.info.is_member = true
                this.$emit('refresh-members')
            },
            unjoin(){
                this.$root.$emit('bv::show::modal', 'unjoin')
            },
            join(){
                this.$root.$emit('bv::show::modal', 'modal-join')
            },
            refresh(team){
                this.info.name = team.name
                this.info.country = team.country
            }

        }
    }
</script>
<style scoped>
    .outer {
        margin: auto;
        position: relative;
    }

    .inner {
        position: absolute;
        top: 40px;
        left: 46px;
    }
    /deep/ .vue-image-crop-upload .vicp-wrap{
      background-color: #2a3042!important;
    }
</style>
<script>

    import appConfig from "@/app.config.json";
    import simplebar from "simplebar-vue";
    import helper from "@/assets/helpers/helper";
    import ProfileTeamHeader from "@/components/profileTeamHeader";
    import Invite from "@/components/popups/invite";
    import RequestCaptain from "@/components/popups/requestCaptain";
    import TeamCompetitions from "../../components/TeamCompetitions";


    /**
     * Contacts-Profile component
     */
    export default {
        page: {
            title: "Profile Team",
            meta: [{name: "description", content: appConfig.description}]
        },
        name: 'ProfilTeam',
        components: {TeamCompetitions, RequestCaptain, Invite, ProfileTeamHeader,  simplebar},
        data() {
            return {
                title: "Profile Team",
                profil_info:{
                    captain:{},
                  is_member:false
                },
                team_evolution : {
                    series: [{
                        name: 'points',
                        data: []
                    }],
                    chartOptions: {
                        chart: {
                            height: 320,
                            type: 'line',
                            toolbar: 'false',
                            dropShadow: {
                                enabled: true,
                                color: '#000',
                                top: 18,
                                left: 7,
                                blur: 8,
                                opacity: 0.2
                            },
                        },
                        dataLabels: {
                            enabled: false
                        },
                        colors: ['#556ee6'],
                        stroke: {
                            curve: 'smooth',
                            width: 3,
                        },
                    }
                },


                settings: {
                    "dots": true,
                    "edgeFriction": 0.35,
                    "infinite": false,
                    "speed": 500,
                    "slidesToShow": 2,
                    "slidesToScroll": 1
                },

                team_members:[],
                isLoadedProfil:false,

                loadMem:false,

            };
        },
 
        created(){
            this.LoadProfil()

        },
        methods:{

           async removeMember(id,index){
             let a =   await helper.deleteItem('teams/'+this.$route.params.id+'/members/'+id);
             if(a) this.team_members.splice(index,1);

            },
            async loadMembers(){
                this.team_members = await helper.getAll('teams/'+this.$route.params.id+'/users');
                this.loadMem = true
            },
            async LoadProfil(){

                this.profil_info = await helper.getAll('teams/'+this.$route.params.id);
               document.title = this.profil_info.name + ' | CRISIS'
              this.$forceUpdate()
                let evolution = await helper.getAll('teams/'+this.$route.params.id+'/evolution');
                this.isLoadedProfil = true;
                await this.loadMembers()
if(evolution) {
  let result = {}
  let labels = []
  for (var i = 0; i < evolution.data.length; i++) {
    labels.push(Object.keys(evolution.data[i]));
    result[Object.keys(evolution.data[i])] = Object.values(evolution.data[i])[0];
  }
  this.$set(this.team_evolution.series, 0, {name: 'Number', data: Object.values(result)})
  this.team_evolution.chartOptions = {
    ...this.team_evolution.chartOptions, ...{
      labels: labels
    }
  }
}
            },

            refreshCaptain(){
                this.loadMembers()
                this.profil_info.is_captain = true
                this.profil_info.is_member = true
                this.profil_info.captain = this.$store.state.user
                this.profil_info.captain.user_id = this.$store.state.user.id

            }
        },

    };
</script>

<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <profile-team-header :info="profil_info" v-on:refresh-members="loadMembers"></profile-team-header>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-3" >

                <div class="card">
                    <div class="card-body ">
                        <h4 class="card-title  mb-4" >
                           <span class="my-auto">{{$t('profil_team.members.title')}}</span>
                                <button  class="btn btn-primary pull-right btn-xl " v-if="profil_info.is_captain"   v-b-modal.modal-invite style="float: right">{{$t('profil_team.invite')}}</button>
                                <invite :code="profil_info.code"/>
                                <button v-b-modal.request-captain  class="btn btn-primary pull-right btn-xl " v-if="profil_info.captain == null && isLoadedProfil && profil_info.is_member"   style="float: right"  >{{$t('profil_team.request')}}</button>
                                <request-captain v-on:refresh="refreshCaptain"/>
                        </h4>
                        <b-skeleton-table :rows="2" :columns="3" v-if="!loadMem"></b-skeleton-table>
                        <b-card-text v-if="team_members.length">
                            <simplebar style="max-height: 300px;padding-right: 12px">
                                <table class="table text-center  ">

                                    <tbody>
                                    <tr>

                                        <th class="text-left">{{$t('profil_team.members.username')}}</th>
                                        <th>{{$t('profil_team.members.score')}}</th>
                                        <th></th>
                                    </tr>
                                    <tr v-for="(data,index) of team_members" :key="data.id">
                                        <td class="text-xl-left d-flex">

                                           <b-avatar :src="data.user.avatar"  class="mr-3" size="sm"></b-avatar>
                                          <i v-if="profil_info.captain && data.user_id == profil_info.captain.user_id" class="bx text-primary mr-2  bx bxs-award"></i>
                                          <router-link :to="{name:'ProfilUser',params:{id:data.user_id}}" class="h6 link" :title="$t('profil_team.members.join_at')+': '+new Date(data.created_at).toLocaleString()">{{data.user.username}}</router-link>
                                        </td>
                                        <td>
                                            <h5 class="font-size-14 mb-0">{{data.user.score?data.user.score:0}}</h5>
                                        </td>
                                        <td style="max-width: 8px" v-if="profil_info.captain && $store.state.user.user_id == profil_info.captain.user_id">
                                            <i class="fas text-danger fa-trash-alt link" v-if="data.user_id != profil_info.captain.user_id" @click="removeMember(data.user_id,index)"></i>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </simplebar>
                        </b-card-text>
                        <b-card-text v-if="team_members.length == 0 && loadMem">
                           {{$t('profil_team.no_member')}}
                        </b-card-text>
                    </div>
                </div>
            </div>

            <div class="col-xl-6">

                <team-competitions :is_member="profil_info.is_member"/>

            </div>


            <!-- end col -->
            <div class="col-xl-3">
                <div class="card">
                    <div class="card-body">
                        <div class="clearfix">
                            <h4 class="card-title mb-4">{{$t('profil_team.evolution')}}</h4>
                        </div>

                        <div class="row">
                            <div class="col-lg-12">
                                <apexchart
                                        class="apex-charts"
                                        dir="ltr"
                                        height="320"
                                        :series="team_evolution.series"
                                        :options="team_evolution.chartOptions"
                                ></apexchart>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- end row -->
    </div>
</template>
<style scoped>
[v-cloak] { display:none; }
</style>
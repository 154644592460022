<template>
  <div class="card">
    <div class="card-body text-center">
      <h4 class="card-title  mb-4">{{$t('profil_comp.title_tab')}}</h4>
      <button  class="btn btn-primary  btn-xl " @click="$router.push({name:'Competitions'})" v-if="!competitions_sorted.length && is_member && loadComp">{{$t('profil_comp.tab_empty')}}</button>
      <p class="text-muted mx-auto" v-if="!competitions_sorted.length && !is_member && loadComp">{{$t('profil_comp.tab_empty2')}}</p>
      <!-- Table -->
      <b-skeleton-table :rows="4" :columns="6" v-if="!loadComp"></b-skeleton-table>
      <table class=" tab table b-table text-center" role="table" v-if="competitions_sorted.length">
        <thead>
        <tr>
          <th scope="col" >{{$t('profil_comp.name')}}</th>

          <th scope="col" >{{$t('profil_comp.teams')}}</th>
          <th scope="col" @click="sort('rank')" role="columnheader" :aria-sort="(currentSort == 'rank')?currentSortDir:none">{{$t('profil_comp.rank')}}</th>

          <th scope="col" @click="sort('score')" role="columnheader" :aria-sort="(currentSort == 'score')?currentSortDir:none">{{$t('profil_comp.score')}}</th>

          <th scope="col" >{{$t('profil_comp.actions')}}</th>

        </tr>
        </thead>
        <tbody>
        <tr v-for="data of competitions_sorted" :key="data.id">
          <td data-label="Name">
            <router-link  class="h6 link text-white "  :to="{name:'ProfilCompetition',params:{id:data.competition_id}}">{{data.competition.name}}</router-link>
            <p class="text-muted mb-0"
               v-b-tooltip.hover
               :title="$t('profil_comp.join_at')+': '+new Date(data.created_at).toLocaleString()">{{data.competition.time_diff}}</p>
          </td>
          <td data-label="Teams">{{data.competition.teams_count}}</td>

          <td data-label="Rank"> {{data.rank}}</td>

          <td data-label="Score"> {{data.score}}</td>

          <td data-label="Actions" >

            <router-link
                class="badge badge-soft-success font-size-12 m-1 link"
                :to="{name:'ScoreBoardCompetition',params:{id:data.competition_id}}"
            >{{$t('profil_comp.scoreboard')}}</router-link>
            <router-link

                class="badge badge-soft-warning font-size-12 link"
                :to="{name:'statisticsCompetition',params:{id:data.competition_id}}"
            >{{$t('profil_comp.statistics')}}</router-link>

          </td>
        </tr>


        </tbody>
      </table>
    </div>
    <div class="row  mb-4" v-if="competitions_sorted.length">
      <div class="col">
        <div class="text-center">
          <paginate :current_page=current_page :last_page_url=last_page_url
                    v-on:change-page="changePage"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Paginate from "./pagination";
import helper from "@/assets/helpers/helper";

export default {
  name: "TeamCompetitions",
  components: {Paginate},
  props:['is_member'],
  data(){
    return{
      loadComp:false,
      none:'none',
      current_page: 1,
      last_page_url: 1,
      currentSort:'name',
      currentSortDir:'ascending',
      team_competitions:[],
    }
  },
  created(){
    this.loadCompetitions(1)
  },
  methods: {
    async loadCompetitions(page) {

      let team_competitions = await helper.loadData('teams/' + this.$route.params.id + '/competitions', page);
      this.team_competitions = team_competitions.data.map( p => ({ ...p,rank:p.ranks.length?p.ranks[0].rank:'-'}));
      console.log(team_competitions.data)
      this.loadComp = true
      this.current_page = team_competitions.current_page
      this.last_page_url = team_competitions.last_page;
    },
    sort:function(s) {
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='ascending'?'descending':'ascending';
      }
      this.currentSort = s;
    },
    changePage(page) {
      this.loadCompetitions(page)
    },
  },
  computed:{
    competitions_sorted:function() {
      let competitions = this.team_competitions;
      return competitions.sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'descending') modifier = -1;
        if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    }
  },
}
</script>

<style scoped>

</style>
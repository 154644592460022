<template>
    <b-modal id="request-captain" ref="modal" size="md" centered  hide-header hide-footer>

        <p class="my-4 text-center text-white" >Do you want to Be captain of this team ?</p>
        <div class="row text-center align-items-center">
            <div class="col-xl-6 m-auto">
                <button class="btn btn-primary mr-2" @click="confirm">Yes</button>
                <button class="btn btn-danger" @click="hideModal">Cancel</button>
            </div>
        </div>
    </b-modal>
</template>

<script>

    import helper from "@/assets/helpers/helper";

    export default {
        name: "requestCaptain",
        methods:{
            hideModal() {
                this.$refs['modal'].hide()
            },
         async confirm() {
              let r =  await helper.postReturnData('teams/'+this.$route.params.id+'/request_captain');
               if(r){
                   this.hideModal()
                   this.$emit('refresh')
               }

            },


        }
    }
</script>
